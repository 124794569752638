export enum InstanceKeyObject {
  InstanceKey = 'instance_key',
  PublicKey = 'public_key',
  PublicKeyPEM = 'public_key_pem',
  FapiKey = 'fapi_key',
  SecretKey = 'secret_key',
}

export type InstanceKey = {
  id: string;
  instance_id: string;
  name: string;
  object: InstanceKeyObject;
  secret: string;
  created_at: number;
  updated_at: number;
};

export const FRAMEWORKS = [
  {
    name: 'Next.js',
    slug: 'nextjs',
    publishable_key: 'NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY',
    secret_key: 'CLERK_SECRET_KEY',
    envFile: '.env.local',
  },
  {
    name: 'React',
    slug: 'react',
    publishable_key: 'REACT_APP_CLERK_PUBLISHABLE_KEY',
    envFile: '.env',
  },
  {
    name: 'Remix',
    slug: 'remix',
    publishable_key: 'CLERK_PUBLISHABLE_KEY',
    secret_key: 'CLERK_SECRET_KEY',
    envFile: '.env',
  },
  {
    name: 'RedwoodJS',
    slug: 'redwoodjs',
    publishable_key: 'CLERK_PUBLISHABLE_KEY',
    secret_key: 'CLERK_SECRET_KEY',
    envFile: '.env',
  },
  {
    name: 'Gatsby',
    slug: 'gatsby',
    publishable_key: 'GATSBY_CLERK_PUBLISHABLE_KEY',
    secret_key: 'CLERK_SECRET_KEY',
    envFile: '.env',
  },
  {
    name: 'Expo',
    slug: 'expo',
    publishable_key: 'CLERK_PUBLISHABLE_KEY',
    envFile: '.env',
  },
  {
    name: 'Chrome Extension',
    slug: 'chrome',
    publishable_key: 'CLERK_PUBLISHABLE_KEY',
    envFile: '.env',
    url: 'CLERK_FRONTEND_API',
  },
];
